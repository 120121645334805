import { URL } from "@/api/mailbox.js";
import { mapGetters, mapActions } from "vuex";
import formatDate from "@/plugins/formatDate.js";
import paginate from "@/components/paginate.vue";
import formatTime from "@/plugins/formatTime.js";
import { TIME_INTERVAL } from "@/consts";
import loadingInvoice from "@/components/loadingInvoice";
import searchInput from "@/components/searchInput";

export default {
  components: {
    paginate,
    loadingInvoice,
    searchInput,
  },
  data() {
    return {
      idMailbox: null,
      checkAll: false,
      checkedMail: [],
      mails: [],
      isIndeterminate: false,
      search: "",
      paginate: {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      isUpdateMailBox: false,
      idInterval: null,
      isDetailClickable: true,
      isLoading: false,
    };
  },

  async created() {
    this.search = this.getKeySearchMailBox;
    this.paginate = this.getMailBoxPaginate;
    if (this.selectCurrentCompany) {
      this.getListMailBox();
    }
  },

  methods: {
    ...mapActions(["loading"]),

    async getListMailBox() {
      this.loading(true);
      this.isLoading = true;
      this.checkAll = false;
      this.checkedMail = [];
      this.isIndeterminate = false;
      const params = {};
      if (this.search) {
        params.key_search = this.search;
      }
      params.company_id = this.selectCurrentCompany;
      params.page = this.paginate.currentPage;
      params.limit = this.paginate.limit;

      const result = await this.$request({
        url: URL.GET_LIST_MAILBOX,
        params,
      });
      const res = result.data;

      if (res.code == 200) {
        this.mails = res.data.data;
        this.isUpdateMailBox = this.mails
          .map((item) => item.is_updating)
          .some((item) => item);

        this.paginate.totalPage = res.data.total_pages;
        this.paginate.totalItem = res.data.total;
        this.paginate.limit = res.data.limit;
        this.$store.dispatch("setMailBoxPaginate", this.paginate);
      }
      this.loading(false);
      this.isLoading = false;
    },

    handleLimitChange(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.getListMailBox();
    },

    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
      this.getListMailBox();
    },

    searchInput() {
      this.paginate.totalPage = 1;
      this.$store.dispatch("setKeySearchMailBox", this.search);
      this.getListMailBox();
    },
    async reReadMailBox (mailId) {
      await this.$request({
        url: URL.REREAD_MAILBOX,
        method: "POST",
        data: {'id': mailId}
      });

      await this.getListMailBox();
    },
    clickRow (id) {
      if (this.isDetailClickable) {
        this.$router.push({ name: 'detailMailBox', params: { mailId: id } })
      }
    },
    formatTime,
    formatDate,
  },

  computed: {
    ...mapGetters([
      "selectCurrentCompany",
      "getUserIndexRole",
      "selectCurrentCompany",
      "getKeySearchMailBox",
      "getMailBoxPaginate"
    ]),
  },

  watch: {
    selectCurrentCompany(newVal) {
      if (newVal == '-1' || !newVal) return;
      this.paginate = {
        ...this.paginate,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      };
      this.getListMailBox();
    },

    currentPage() {
      this.getListMailBox();
    },

    isUpdateMailBox(newVal) {
      if (newVal) {
        clearInterval(this.idInterval);
        this.idInterval = setInterval(() => {
          if (newVal == -1 || !newVal) return;
          this.getListMailBox();
        }, TIME_INTERVAL);
      } else {
        clearInterval(this.idInterval);
      }
    },

    async $route() {
      this.isUpdateMailBox = false;
    },
  },
  beforeRouteLeave() {
    clearInterval(this.idInterval);
  },
};
